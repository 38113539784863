<!-- 个人中心 -->
<template>
  <div class="personal-page">
    <img src="@/assets/logo.png" class="wechat-logo" />
    <p class="status">{{ status | formatStatus }}</p>
    <p class="tips">绑定微信号会同步您航都院OA系统中的消息和通知</p>
    <button @click="wxAuth" class="button" v-if="2 == status">
      绑定微信号
    </button>
  </div>
</template>

<script>
import { queryQrcodeByAccount } from "@/api/account";
import Cookies from "js-cookie";
import closeElement from "@/utils/closeElement";
export default {
  data() {
    return {
      status: "99", //2-"未绑定" 1-"已绑定"
    };
  },
  name: "bindWechat",
  components: {},
  computed: {},
  filters: {
    formatStatus(val) {
      const rules = {
        2: "未绑定",
        1: "已绑定",
      };
      return rules[val] || "";
    },
  },
  mounted() {
    let isBind = localStorage.getItem("isBind");
    if (isBind) {
      localStorage.removeItem("isBind");
      localStorage.removeItem("isBind", { path: `/bindWechat/${this.$route.params.time}` });
      closeElement();
    } else {
      this.getStatus();
    }
  },
  methods: {
    wxAuth() {
      const appid = process.env.VUE_APP_WXAPPID;
      const redirect_uri = encodeURIComponent(
        `${window.location.origin}${window.location.pathname}`
      ).replace("h5BindWechat", "bindWechatWithcode");
      const state = "";
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
      window.location.replace(url);
    },
    getStatus() {
      let postData = {
        staffCode: this.$route.params.id,
      };
      queryQrcodeByAccount(postData).then((res) => {
        let { data } = res;
        if (data) {
          this.status = res.data;
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.personal-page {
  display: flex;
  background: #fff;
  flex-direction: column;
  align-items: center;
  .wechat-logo {
    margin-top: 2rem;
    width: 4rem;
    height: 4rem;
  }
  .status {
    font-size: 0.4rem;
    color: #09bb07;
    height: 0.5rem;
    margin-top: 0.2rem;
  }
  .tips {
    color: #666;
    font-size: 0.26rem;
    margin-top: 1rem;
    max-width: 6.9rem;
  }
  .button {
    margin-top: 0.3rem;
    width: 6.9rem;
    height: 0.8rem;
    margin-bottom: 1rem;
    background: #0094EE;
    border-radius: 0.1rem;
    color: #fff;
    font-size: 0.3rem;
  }
}
</style>
